import React from "react";
import { Query } from "react-apollo";
import CalendarTextIcon from "mdi-react/CalendarTextIcon";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { List, ErrorMessage, Loader, VasuOwnReservations } from "../..";

const VasuChildReservations = ({ child }) => {
  const { t } = useTranslation();
  return (
    <List title={t("Vasu.discussions")} icon={CalendarTextIcon}>
      <Query query={GET_RESERVED_SLOTS} variables={{ id: child.id, afterDate: DateTime.local().minus({ days: 1 }).toISODate()  }}>
        {({ data, loading, error }) => {
          if (loading)
            return <Loader text={t("General.loading")} centered large />;
          if (error) {
            console.log(error);
            return (
              <ErrorMessage centered large>
                {t("General.loading_error")}
              </ErrorMessage>
            );
          }

          let slots =
            data &&
            data.child &&
            data.child.reservedEarlyEducationPlanningSlots;
          slots.sort((a, b) => {
            if (a.startTime === b.startTime) {
              return (
                (a.endTime
                  ? DateTime.fromISO(a.endTime).toJSDate()
                  : DateTime.fromISO(a.startTime)
                      .set({ hour: 0, minute: 0 })
                      .toJSDate()) -
                (b.endTime
                  ? DateTime.fromISO(b.endTime).toJSDate()
                  : DateTime.fromISO(b.startTime)
                      .set({ hour: 0, minute: 0 })
                      .toJSDate())
              );
            } else {
              return (
                DateTime.fromISO(a.startTime).toJSDate() -
                DateTime.fromISO(b.startTime).toJSDate()
              );
            }
          });

          return <VasuOwnReservations slots={slots} profile />;
        }}
      </Query>
    </List>
  );
};

const GET_RESERVED_SLOTS = gql`
  query reservedSlots(
    $id: ID!
    $date: ISO8601DateTime
    $afterDate: ISO8601Date = null
  ) {
    child(id: $id, date: $date) {
      id
      reservedEarlyEducationPlanningSlots(afterDate: $afterDate) {
        id
        startTime
        endTime
        daycareGroup {
          id
          name
        }
        child {
          id
          firstName
        }
        notes
      }
    }
  }
`;

export default VasuChildReservations;
