import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "mdi-react/CloseIcon";
import styles from "./VideoPlayer.module.scss"
import { isIosApp, isNativeApp, Button } from "../../../shared";
import ReactHLS from 'react-hls';
import dayjs from "dayjs";
import spacetime from "spacetime";
import ReactPlayer from 'react-player'

import { useTranslation } from "react-i18next";
import PlayIcon from "mdi-react/PlayCircleIcon";

const VideoPlayer = ({video, setShowVideo, currentIndex, children, staff}) => {

  const {description, daycareGroupNames, streamingUrl, downloadUrl} = video

  const date = dayjs(video.createdAt).format("DD.MM.YYYY")
  const groups = video.daycareGroupNames.length === 0 ? "" : "(" + video.daycareGroupNames.join(", ") + ")"
  const [loadVideo, setLoadVideo] = useState(false)
  const [showIosDescription, setShowIosDescription] = useState(true)
  const rp = useRef()

  const clickOutside = (event) => {
    /*
    if(staff && event.target.parentElement.className !== "player-area"){
      setShowVideo(false)
   }
   */
  }

  useEffect(() => {
    document.addEventListener('click', clickOutside)
    return () => {
      document.removeEventListener('click', clickOutside)
    }
  })

  useEffect(() => {
    setLoadVideo(false)
  }, [currentIndex])


  useEffect(() => {
    console.log(rp);
  }, [rp])

  const onEnded = () => {
  //  rp.current.showPreview()
  //  setShowIosDescription(true)
  setLoadVideo(false)
  }

  if(staff){
    const photo = video
    return(
      <ReactHLS width={'100%'} url={streamingUrl} autoplay={false} />
    )
  }

  if(window.innerWidth > 600){

    if(!loadVideo){
      return(
        <>
        {children}
        <div style={{backgroundImage: `url(${video.thumbnailUrl})`}} className={styles.backgroundImage}/>
        <PlayIcon className={styles.icon} onClick={() => setLoadVideo(true)}/>
        </>
      )
    } else {
      return (
        <ReactHLS height={window.innerHeight}  url={streamingUrl} autoplay={true} />
      )
    }


  } else {

    if(isNativeApp() && isIosApp()){
  //if(true){

      if(!loadVideo){
        return(
          <>
            {children}
            <div style={{backgroundImage: `url(${video.thumbnailUrl})`}} className={styles.backgroundImage}/>
            <PlayIcon className={styles.icon} onClick={() => setLoadVideo(true)}/>
          </>
        )
      } else {
        return (
          <>
          <ReactPlayer
            ref={rp}
            url={streamingUrl}
            width={"100%"}
            controls={true}
            style={{
              top: 100,
              position: 'relative'
            }}
            playing={true}
            onReady={() => setShowIosDescription(false)}
            onEnded={() => {
              onEnded()
            }}
          />
          </>
        )
      }

    }
    else {
      if(!loadVideo){
        return(
          <>
            {children}
            <div style={{backgroundImage: `url(${video.thumbnailUrl})`}} className={styles.backgroundImage}/>
            <PlayIcon className={styles.icon} onClick={() => setLoadVideo(true)}/>
          </>
        )
      } else {
        return (
          <div style={{marginTop: 50}}>
          <ReactHLS
          height={window.innerHeight - 100}
          width={window.innerWidth}
          videoProps={{controlslist: "nofullscreen", playsInline: true, poster: video.thumbnailUrl}}
          url={streamingUrl}
          autoplay/>
          </div>
        )
      }
    }

  }
}



const PhotoDownloadButton = ({ url }) => {
  const { t } = useTranslation();
  if (isNativeApp()) {
    if (isIosApp()) {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.downloadPhoto &&
        window.webkit.messageHandlers.downloadPhoto.postMessage
      ) {
        return (
          <Button small blue onClick={() => window.webkit.messageHandlers.downloadPhoto.postMessage(url)}>
            {t("Photos.download")}
          </Button>
        );
      } else {
        return null;
      }
    } else {
      if (window.TouGoAndroid && window.TouGoAndroid.downloadPhoto) {
        return (
          <Button small blue onClick={() => window.TouGoAndroid.downloadPhoto(url)}>
            {t("Photos.download")}
          </Button>
        );
      } else {
        return null;
      }
    }
  } else {
    return (
      <Button small blue wrapper="a" href={url}>
        {t("Photos.download")}
      </Button>
    );
  }
};

const Modal = ({ children, ...props }) => (
  <div className={styles.Modal} {...props}>
    {children}
  </div>
);

export default VideoPlayer
