// Assets
import "./assets/fonts/Maax-Bold-205TF.eot";
import "./assets/fonts/Maax-Bold-205TF.ttf";
import "./assets/fonts/Maax-Bold-205TF.woff";
import "./assets/fonts/Maax-Bold-205TF.woff2";

import "./assets/fonts/Maax-Italic-205TF.eot";
import "./assets/fonts/Maax-Italic-205TF.ttf";
import "./assets/fonts/Maax-Italic-205TF.woff";
import "./assets/fonts/Maax-Italic-205TF.woff2";

import "./assets/fonts/Maax-Medium-205TF.eot";
import "./assets/fonts/Maax-Medium-205TF.ttf";
import "./assets/fonts/Maax-Medium-205TF.woff";
import "./assets/fonts/Maax-Medium-205TF.woff2";

import "./assets/fonts/Maax-Regular-205TF.eot";
import "./assets/fonts/Maax-Regular-205TF.ttf";
import "./assets/fonts/Maax-Regular-205TF.woff";
import "./assets/fonts/Maax-Regular-205TF.woff2";

// Global styles!
import "./styles/global.scss";

// SCSS variables
import "./styles/variables.scss";

// Components

import Sidebar from "./components/Sidebar/Sidebar";
import Loader from "./components/Loader/Loader";
import CenteredContent from "./components/CenteredContent/CenteredContent";
import MessageBox from "./components/Messages/MessageBox/MessageBox";
import { MessageTabs, MessageTab } from "./components/MessageTabs/MessageTabs";
import MessagesList from "./components/Messages/MessagesList/MessagesList";
import MessageSearch from "./components/Messages/MessageSearch/MessageSearch";
import MessageSearchBox from "./components/Messages/MessageSearchBox/MessageSearchBox";
import MessageSearchResult from "./components/Messages/MessageSearchResult/MessageSearchResult";
import ReplyInput from "./components/Messages/ReplyInput/ReplyInput";
import Thread from "./components/Messages/Thread/Thread";
import EmptyThread from "./components/Messages/EmptyThread/EmptyThread";
import LoadMoreButton from "./components/Messages/LoadMoreButton/LoadMoreButton";
import ThreadItem from "./components/Messages/ThreadItem/ThreadItem";
import ThreadList from "./components/Messages/ThreadList/ThreadList";
import ThreadGroup from "./components/Messages/ThreadGroup/ThreadGroup";
import ArchivedIndicator from "./components/Messages/ArchivedIndicator/ArchivedIndicator";
import ContainerWithSideContent from "./components/ContainerWithSideContent/ContainerWithSideContent";
import Avatar from "./components/Avatar/Avatar";
import OverlappingAvatars from "./components/OverlappingAvatars/OverlappingAvatars";
import NarrowContainer from "./components/NarrowContainer/NarrowContainer";
import Button, { OutlineButton } from "./components/Button/Button";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";
import MainLayout from "./components/MainLayout/MainLayout";
import InlineHelp from "./components/InlineHelp/InlineHelp";
import SidebarHeading from "./components/SidebarHeading/SidebarHeading";
import SearchInput from "./components/SearchInput/SearchInput";
import SearchResult from "./components/SearchResult/SearchResult";
import FullHeightContainer from "./components/FullHeightContainer/FullHeightContainer";
import MarkAllAsReadButton from "./components/Messages/MarkAllAsReadButton/MarkAllAsReadButton";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import VasuOwnReservations from "./components/VasuOwnReservations/VasuOwnReservations";
import VasuChildReservations from "./components/VasuChildReservations/VasuChildReservations";
import ChildVasu from "./components/ChildVasu/ChildVasu";
import VasuDocumentItem from "./components/VasuDocumentItem/VasuDocumentItem";
import VasuDocumentMeta from "./components/VasuDocumentMeta/VasuDocumentMeta";

import {
  SidebarPicker,
  SidebarPickerContainer,
} from "./components/SidebarPicker/SidebarPicker";
import {
  UserAgent,
  isNativeApp,
  isAndroidApp,
  isIosApp,
  userAgentPlatform,
  nativeAppVersionGreaterThan,
  iOSversion,
} from "./components/UserAgent/UserAgent";
import {
  SidebarButton,
  SidebarDropdownButton,
  SidebarSubButton,
} from "./components/SidebarButton/SidebarButton";
import StickyActionPanel from "./components/StickyActionPanel/StickyActionPanel";
import DayPicker, {
  sortDates,
  reservationGroupColors,
  rangeToDays,
} from "./components/DayPicker/DayPicker";
import NoMatch from "./components/NoMatch/NoMatch";
import AllergyListing from "./components/Profile/AllergyListing/AllergyListing";
import LegacyAllergiesAndConditions from "./components/Profile/LegacyAllergiesAndConditions/LegacyAllergiesAndConditions";
import ProfileHeader from "./components/Profile/ProfileHeader/ProfileHeader";
import ReservationStats from "./components/Profile/ReservationStats/ReservationStats";
import TrustedPeopleListing from "./components/Profile/TrustedPeopleListing/TrustedPeopleListing";
import SpecialCareListing from "./components/Profile/SpecialCareListing/SpecialCareListing";
import CareNotes from "./components/Profile/CareNotes/CareNotes";
import PhotoUsageRights from "./components/Profile/PhotoUsageRights/PhotoUsageRights";
import AdditionalInformation from "./components/Profile/AdditionalInformation/AdditionalInformation";
import ReservationForm, {
  SelectedDays,
} from "./components/ReservationForm/ReservationForm";
import Toggle from "./components/Toggle/Toggle";
import Thumbnail from "./components/Thumbnail/Thumbnail";
import ThumbnailGrid from "./components/ThumbnailGrid/ThumbnailGrid";
import ListItemNotifier from "./components/ListItemNotifier/ListItemNotifier";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import TemporaryAnnouncement from "./components/TemporaryAnnouncement/TemporaryAnnouncement";

// Helpers
import en from "./localization/en.json";
import fi from "./localization/fi.json";
import { useTougoCookies, tougoCookies } from "./helpers/cookieHelpers";
import { retry } from "./helpers/retry";
import { isCurrentUserMessageAuthor } from "./helpers/userHelpers";
import { pushQueryToHistory } from "./helpers/historyPusher";
import {
  List,
  ListItem,
  ListCheckbox,
  ListAction,
  ListContent,
  ListForm,
  ListFootnote,
} from "./components/ListItem/ListItem";

export {
  Sidebar,
  MessageBox,
  MessagesList,
  MessageTabs,
  MessageTab,
  ReplyInput,
  Thread,
  EmptyThread,
  LoadMoreButton,
  ThreadItem,
  ThreadList,
  ThreadGroup,
  ContainerWithSideContent,
  Avatar,
  OverlappingAvatars,
  Button,
  OutlineButton,
  ErrorMessage,
  Loader,
  CenteredContent,
  MainLayout,
  SidebarButton,
  SidebarDropdownButton,
  SidebarSubButton,
  SidebarHeading,
  SidebarPickerContainer,
  SidebarPicker,
  FullHeightContainer,
  UserAgent,
  userAgentPlatform,
  isNativeApp,
  isAndroidApp,
  isIosApp,
  SearchInput,
  SearchResult,
  StickyActionPanel,
  DayPicker,
  rangeToDays,
  sortDates,
  reservationGroupColors,
  NoMatch,
  en,
  fi,
  isCurrentUserMessageAuthor,
  pushQueryToHistory,
  List,
  ListItem,
  ListCheckbox,
  ListAction,
  ListContent,
  ListForm,
  ListFootnote,
  NarrowContainer,
  InlineHelp,
  AllergyListing,
  ProfileHeader,
  LegacyAllergiesAndConditions,
  ReservationStats,
  TrustedPeopleListing,
  SpecialCareListing,
  CareNotes,
  PhotoUsageRights,
  AdditionalInformation,
  useTougoCookies,
  tougoCookies,
  retry,
  ReservationForm,
  SelectedDays,
  Toggle,
  ArchivedIndicator,
  MessageSearch,
  MessageSearchBox,
  MessageSearchResult,
  MarkAllAsReadButton,
  Thumbnail,
  ThumbnailGrid,
  ListItemNotifier,
  VideoPlayer,
  VasuOwnReservations,
  VasuChildReservations,
  nativeAppVersionGreaterThan,
  iOSversion,
  ComingSoon,
  ChildVasu,
  VasuDocumentItem,
  VasuDocumentMeta,
  TemporaryAnnouncement,
};
