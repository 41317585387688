export const isAndroidApp = () => {
  if (window.navigator.userAgent.includes("TouGoParentsAndroid")) {
    return true;
  }
  return false;
};

export const isIosApp = () => {
  if (window.navigator.userAgent.includes("TouGoParentsIos")) {
    return true;
  }
  return false;
};

export const nativeAppVersion = () => {
  if (isNativeApp()) {
    const regexp = /(TouGoParentsIos|TouGoParentsAndroid)\/([\d\.]+)/i;
    const matches = window.navigator.userAgent.match(regexp);
    console.log("matches", matches);
    return matches[2];
  }
};

export const nativeAppVersionGreaterThan = (oldVersion) => {
  console.log(
    "nativeAppVersionGreaterThan",
    nativeAppVersion(),
    oldVersion,
    nativeAppVersion() > oldVersion
  );
  return nativeAppVersion() > oldVersion;
};

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
};

export const isNativeApp = () => {
  return isAndroidApp() || isIosApp();
};

export const userAgentPlatform = () => {
  if (isAndroidApp()) {
    return "android";
  } else if (isIosApp()) {
    return "ios";
  } else {
    return "web";
  }
};

export const UserAgent = ({ web, android, ios, children }) => {
  if (web && !isNativeApp()) {
    return children;
  }
  if (android && isAndroidApp()) {
    return children;
  }
  if (ios && isIosApp()) {
    return children;
  }
  return null;
};
