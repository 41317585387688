import React from "react";
import Honeybadger from "honeybadger-js";
import HoneyBadgerErrorBoundary from "@honeybadger-io/react";
import ErrorIcon from "mdi-react/ErrorIcon";
import classNames from "classnames";
import { ReactComponent as LogoSymbol } from "../../assets/images/logo-symbol-blue.svg";

import { tougoCookies } from "../../shared";
import styles from "./ErrorBoundary.module.scss";

const honeybadgerApiKey = process.env.REACT_APP_HONEYBADGER_API_KEY || "xxxxxxxx";
const honeybadgerEnv = process.env.REACT_APP_HONEYBADGER_ENV || process.env.REACT_APP_CONTEXT || process.env.NODE_ENV;

const config = {
  api_key: honeybadgerApiKey,
  environment: honeybadgerEnv,
  revision: process.env.REACT_APP_COMMIT_REF || "master",
  debug: process.env.NODE_ENV !== "production",
  disabled: process.env.NODE_ENV !== "production",
};

const honeybadger = Honeybadger.configure(config);

class ErrorComponent extends React.Component {
  render() {
    const errorClassNames = classNames(styles.error, {
      [styles.centered]: this.props.centered,
    });
    if (this.props.error && this.props.error.message && this.props.error.message.includes("Loading chunk")) {
      return (
        <div className={styles.networkError}>
          <div className={styles.networkErrorInner}>
            <LogoSymbol className={styles.networkErrorIcon} />
            Ei verkkoyhteyttä
          </div>
        </div>
      );
    } else {
      return (
        <div className={errorClassNames}>
          <div className={styles.inner}>
            <ErrorIcon className={styles.icon} />
            Tapahtui virhe / There was an error
          </div>
        </div>
      );
    }
  }
}

class ErrorBoundary extends HoneyBadgerErrorBoundary {
  componentDidMount() {
    Honeybadger.setContext({ user_id: tougoCookies.get("sessionUserId") });
  }
}

ErrorBoundary.defaultProps = { honeybadger, ErrorComponent };

export default ErrorBoundary;
